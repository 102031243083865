// Core

@import 'core';

// Fonts

@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

// Widgets

@import 'widgets';

// Components

@import 'components';

// Layout Components

@import 'layout-components';

// Layout Blueprints

@import 'layout-blueprints';

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

// Kargoplex-specific
#root {
  height: 100vh;
}

.block-ui {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .block-ui-container {
    z-index: 2000;
  }
}
