@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
  --light: #fff;
  /* --less-light: #f1f5f9; */
  --less-light: #f4f5fd;
  --grey: rgba(0, 0, 0, 0.54);
  /* --dark: rgba(0, 0, 0, 0.87); */
  --dark: #3b3e66;
  --title: 0.8rem;
  --sub-title: 0.7rem;
  --active: rgb(70, 62, 175);
  --primary: #3c44b1;
  /* --primary-text: rgba(0, 0, 0, 0.87); */
  --primary-text: #3b3e66;
  --secondary-text: rgba(0, 0, 0, 0.54);
  --color-divider: rgba(0, 0, 0, 0.12);
}

.c-text-primary {
  color: var(--primary-text);
}

.c-text-secondary {
  color: var(--secondary-text);
}

.c-flex-col {
  display: flex;
  flex-direction: column;
}

.c-flex {
  display: flex;
}

.c-justify-content-between {
  justify-content: space-between;
}

.c-justify-content-center {
  justify-content: center;
}

.c-justify-content-end {
  justify-content: end;
}

.c-items-center {
  align-items: center;
}

.c-items-end {
  align-items: flex-end;
}

.c-gap-0 {
  gap: 0;
}

.c-gap-1 {
  gap: 1em;
}

.c-gap-half {
  gap: 0.5em;
}

.c-gap-2 {
  gap: 2em;
}

.c-flex-wrap {
  flex-wrap: wrap;
}

.c-flex-1 {
  flex: 1;
}

.c-w-full {
  width: 100%;
}

.c-w-half {
  width: 50%;
}

.c-w-300 {
  width: 300px;
}

.c-bg-light {
  background-color: var(--light);
}

.c-bg-little-dark {
  background: rgb(224, 222, 222) !important;
}

.c-bg-dark {
  background-color: var(--dark);
}

.c-bg-less-light {
  background-color: var(--less-light);
}

.c-heading {
  color: var(--dark);
  font-size: var(--title);
}

.c-sub-title {
  color: var(--grey);
  font-size: var(--sub-title);
}

.c-font-small {
  font-size: var(--sub-title);
}

.c-font-xs {
  font-size: 0.6rem;
}

.c-font-title {
  font-size: var(--title);
}

.c-font-light {
  color: var(--grey);
}

.c-font-white {
  color: var(--light);
}

.c-font-dark {
  color: var(--dark);
}

.c-mb-0 {
  margin-bottom: 0;
}

.c-mb-1 {
  margin-bottom: 1em;
}

.c-mb-2 {
  margin-bottom: 2em;
}

.c-mt-1 {
  margin-top: 1em;
}

.c-mt-2 {
  margin-top: 2em;
}

.c-mt-half {
  margin-top: 0c-5em;
}

.c-rounded {
  border-radius: 9px;
}

.c-p-1 {
  padding: 1em;
}

.c-p-2 {
  padding: 2em;
}

.c-p-half {
  padding: 0.5em;
}

.c-py-1 {
  padding: 1em 0;
}

.c-py-half {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}

.c-px-1 {
  padding-left: 1em;
  padding-right: 1em;
}

.c-px-2 {
  padding-left: 2em;
  padding-right: 2em;
}

.c-px-xs {
  padding-left: 0.3em;
  padding-right: 0.3em;
}

.c-pt-1 {
  padding-top: 1em;
}

.c-text-center {
  text-align: center;
}

.c-bg-transparent {
  background-color: transparent;
}

a {
  text-decoration: none;
}

.c-hidden {
  display: none !important;
}

.c-helper-logo {
  height: 50px;
  width: 50px;
}

.c-color-blue {
  color: blue;
}

.c-blue_grad {
  background: rgb(2, 0, 36);
  background-image: linear-gradient(135deg, #06113c 10%, #123597);
}

.c-green_grad {
  background: rgb(54, 135, 92);
  background-image: linear-gradient(0deg, #0ba360 0, #3cba92) !important;
}

.c-orange_grad {
  background: rgb(244, 120, 42);
  background-image: linear-gradient(240deg, #f6d365, #fda085) !important;
}

.c-brown_grad {
  background: rgb(62, 30, 0);
  background-image: linear-gradient(205deg, #f05f57 10%, #360940) !important;
}

.c-relative {
  position: relative;
}

.cursor-pointer {
  cursor: pointer;
}

/* for chat left drawer  */
.chat-left-drawer-collapse {
  width: 90px;
  min-width: 90px;
}

.chat-left-drawer-collapse > .MuiDrawer-paperAnchorLeft {
  left: auto !important;
  border-right: 0 !important;
}

.chat-left-drawer-collapse > .MuiDrawer-paper {
  width: 90px !important;
  min-width: 90px !important;
  padding-top: 74px !important;
}

/* styling the scrollbar  */
::-webkit-scrollbar {
  width: 5px;
  height: 1px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  scrollbar-width: thin;
}

/* overwrite antd classes */

/* .ant-upload-select,
.ant-upload-drag {
    border: 1px dashed #c2b9ff !important;
    background-color: white !important;
}

.ant-upload-select:hover,
.ant-upload-drag:hover {
    border: 1px dashed #4431B8 !important;
} */

/* overwrite the login page carousal  */
/* .slider-wrapper {
    border-radius: 16px !important;
} */

/* select label  */
/* #detail-box-label {
    color: #4431B8;
} */

/* slider  */
/* .ant-slider-track {
    background-color: #4431BB !important;
}

.ant-slider-handle::after {
    box-shadow: 0 0 0 2px #4431BB !important;
}

.ant-slider-handle:focus::after,
.ant-slider-handle:hover::after {
    box-shadow: 0 0 0 4px #4431BB !important;
} */

/* .ant-btn-primary {
    background-color: #4431BB !important;
} */

/* .ant-btn-primary:hover {
    background-color: #4431BB !important;
    box-shadow: 0 0 3px #6254bf !important;
}

.ant-btn-default {
    color: #4431B8 !important;
    border-color: #4431B8 !important;
}

.ant-btn-default:hover {
    background-color: #e9e6ff !important;
}


.ant-upload-select,
.ant-upload-drag {
    border: 1px dashed #c2b9ff !important;
    background-color: white !important;
}

.ant-upload-select:hover,
.ant-upload-drag:hover {
    border: 1px dashed #4431B8 !important;
} */

/* new ---------------------------------------------- */
/* overwrite the login page carousal  */
.slider-wrapper {
  border-radius: 16px !important;
}

/* select label  */
#detail-box-label {
  color: var(--primary);
}

/* slider  */
.ant-slider-track {
  background-color: var(--primary) !important;
}

.ant-slider-handle::after {
  box-shadow: 0 0 0 2px var(--primary) !important;
}

.ant-slider-handle:focus::after,
.ant-slider-handle:hover::after {
  box-shadow: 0 0 0 4px var(--primary) !important;
}

.ant-btn-primary {
  background-color: var(--primary) !important;
}

.ant-btn-primary:hover {
  background-color: var(--primary) !important;
  box-shadow: 0 0 3px #6254bf !important;
}

.ant-btn-default {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.ant-btn-default:hover {
  background-color: #e9e6ff !important;
}

.ant-upload-select,
.ant-upload-drag {
  border: 1px dashed #c2b9ff !important;
  background-color: white !important;
}

.ant-upload-select:hover,
.ant-upload-drag:hover {
  border: 1px dashed var(--primary) !important;
}

/* antd input numer  */
.custom-antd-input-number .ant-input-number-group-addon {
  padding: 5px !important;
}

.custom-antd-input-number .ant-input-number-input-wrap {
  padding: 5px !important;
}
